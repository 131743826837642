import { Ability, AbilityBuilder } from "@casl/ability"
import { RolesEnum } from "@/data/enums"
import { parseJwt } from "@/auth/utils"
import Vue from "vue"

export const Action = {
  Manage: "manage", // manage is a special keyword in CASL which represents "any" action.
  Create: "create",
  Read: "read",
  Update: "update",
  Delete: "delete",
}

export const Subjects = {
  All: "all",
  Public: "public",
  Users: "users",
  Customers: "customers",
  Seals: "seals",
  Countries: "countries",
  Areas: "areas",
  Sorts: "sorts",
  SealDistricts: "sealdistricts",
  Processes: "processes",
  Certificates: "certificates",
  Charges: "charges",
  Process: "process",
  Accounting: "accounting"
}

Vue.prototype.$acl = {
  action: Action,
  subjects: Subjects,
}

export const updateAbility = role => {
  const { can, cannot, rules } = new AbilityBuilder(Ability)

  if (role === RolesEnum.Admin || role === RolesEnum.TechnicalAdmin) {
    can(Action.Manage, Subjects.All)
  } else if (role === RolesEnum.Client) {
    can(Action.Read, Subjects.All)
    cannot(Action.Read, Subjects.Users)
  }

  return rules
}

const initialRole = () => {
  const token = localStorage.getItem("accessToken")
  if (!token) return []
  const { role } = parseJwt(token)
  return updateAbility(role)
}

export const isAdmin = () => {
  const token = localStorage.getItem("accessToken")
  if (!token) return false
  const { role } = parseJwt(token)
  return role === RolesEnum.Admin || role === RolesEnum.TechnicalAdmin
}

export default new Ability(initialRole())
