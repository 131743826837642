const RolesEnum = {
  TechnicalAdmin: "TechnicalAdmin",
  Admin: "Siegelmeister",
  // Client: "HV",
}

export const Roles = [RolesEnum.Admin]

export { RolesEnum }


const GroupsEnum = {
  Sonstige: "Sonstige",
  Aroma: "Aroma",
  Bitter: "Bitter",
}

export const Groups = [GroupsEnum.Sonstige, GroupsEnum.Aroma, GroupsEnum.Bitter]

export { GroupsEnum };

const ShareholderEnum = {
  Barth: "Barth",
  HVG: "HVG",
  HV: "HV",
}

export const Shareholder = [ShareholderEnum.Barth, ShareholderEnum.HVG, ShareholderEnum.HV]

export { ShareholderEnum };


const SortGroupsEnum = {
  Sonstige: "Sonstige",
  Aroma: "Aroma",
  Bitter: "Bitter",
}

export const SortGroups = [SortGroupsEnum.Sonstige, SortGroupsEnum.Aroma, SortGroupsEnum.Bitter]

export { SortGroupsEnum };



const SortTypesEnum = {
  Leerung: 'Leerung',
  Hopfenpellets: 'Hopfenpellets',
  Lupulinpellets: 'Lupulinpellets',
}

export const SortTypes = [SortTypesEnum.Hopfenpellets, SortTypesEnum.Lupulinpellets]

export { SortTypesEnum };

const ProcessTypesEnum = {
  VA: 'VA',
  Leerung: 'Leerung',
}

export const ProcessTypes = [ProcessTypesEnum.VA, ProcessTypesEnum.Leerung]

export { ProcessTypesEnum };


const PrintTitlesEnum = {
  DH_VA: 'Deutsches Hopfenerzeugnis (VA)',
  D_VA: 'Hopfenerzeugnis (VA)',
  HaD_VA: 'Hopfenerzeugnis aus Drittland (VA)',
  DH_L: 'Deutsches Hopfenerzeugnis (Leerung)',
  H_L: 'Hopfenerzeugnis (Leerung)',
  HaD_L: 'Hopfenerzeugnis aus Drittland (Leerung)',
}

export const PrintTitles = [PrintTitlesEnum.DH_VA, PrintTitlesEnum.D_VA, PrintTitlesEnum.HaD_VA, PrintTitlesEnum.DH_L, PrintTitlesEnum.H_L, PrintTitlesEnum.HaD_L]

export { PrintTitlesEnum };

const ChargeTypesEnum = {
  VA: 'VA',
  Leerung: 'Leerung',
  Urkunde: 'Urkunde',
  Leer: 'LeereUrkunde',
  Kartonsiegelung: 'Kartonsiegelung'
}
export const ChargeTypes = [ChargeTypesEnum.VA, ChargeTypesEnum.Leerung, ChargeTypesEnum.Certificate]

export { ChargeTypesEnum };

const CertificateTypesEnum = {
  Muster: 'Muster',
  Verlust: 'Verlust',
  Interim: 'Interim',
  IKE: 'IKE',
  SonstigesP: 'SonstigesP',
  SonstigesM: 'SonstigesM',
  Standard: 'Standard',
}
export const CertificateTypes = [
  CertificateTypesEnum.Muster, 
  CertificateTypesEnum.Verlust, 
  CertificateTypesEnum.Interim,
  CertificateTypesEnum.IKE, 
  CertificateTypesEnum.SonstigesP, 
  CertificateTypesEnum.SonstigesM,
  CertificateTypesEnum.Standard
]

export { CertificateTypesEnum };